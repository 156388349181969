import React from "react"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import SocialIcons from '../common/SocialIcons'

import '../../styles/components/hero-full-screen-photo.scss'

class HeroFullScreenPhoto extends React.Component {
  render() {
    const image = getImage(this.props.heroImage)
    const bgImage = convertToBgImage(image)
    return (
      <BackgroundImage
        Tag='article'
        className='hero-full-screen-photo'
        {...bgImage}
      >
        <div className="container medium">
          <SocialIcons type={'light'} position={'absolute'} espaWhite={true} />
          { this.props.heroHiddenH1 && <h1 className="visually-hidden">{this.props.heroHiddenH1}</h1> }
          <div dangerouslySetInnerHTML={{ __html: this.props.heroContent }} className="hero-full-screen-photo__content" />
        </div>
      </BackgroundImage>
    )
  }
}

export default HeroFullScreenPhoto
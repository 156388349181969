import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import SVG from 'react-inlinesvg'
import { GatsbyImage } from "gatsby-plugin-image";

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import HeroFullScreenPhoto from '../components/sections/hero-full-screen-photo'
import SectionHeader from '../components/common/section-header'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, } from 'react-accessible-accordion';
import ContactForm from '../components/sections/contact-form'

import '../styles/pages/page-webdesign.scss'
import { webdesign as servicesSchema } from '../utils/schema/services'
import { webDevelopment } from '../utils/schema/faq'

const pageWebDesign = ({ location }) => {
  const data = useStaticQuery(graphql`{
  ghostPage: ghostPage(tags: {elemMatch: {name: {eq: "#page-web-design"}}}) {
    ...GhostPageFields
  }
  hero: file(relativePath: {eq: "hero/hero-webdesign.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  open: file(relativePath: {eq: "webdesign/open.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 720, layout: CONSTRAINED)
    }
  }
  webBanners: file(relativePath: {eq: "webdesign/webbanners2.png"}) {
    childImageSharp {
      gatsbyImageData(width: 720, layout: CONSTRAINED)
    }
  }
  blogs: file(relativePath: {eq: "webdesign/blogs.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 720, layout: CONSTRAINED)
    }
  }
  hotel: file(relativePath: {eq: "webdesign/hotel.png"}) {
    childImageSharp {
      gatsbyImageData(width: 720, layout: CONSTRAINED)
    }
  }
}
`)
	const page = data.ghostPage

  return <>
      <MetaData
        data={data}
        location={location}
        type="website"
      />
      <Helmet>
        <style type="text/css">{`${page.codeinjection_styles}`}</style>
        <script type="application/ld+json">{JSON.stringify(servicesSchema)}</script>
        <script type="application/ld+json">{JSON.stringify(webDevelopment)}</script>
      </Helmet>
      <Layout viewport={'full'}>
        <article className="page-webdesign">
          <HeroFullScreenPhoto
            heroImage={data.hero.childImageSharp.gatsbyImageData}
            heroContent={
              `<h1 class="h5"><span></span>Κατασκευή ιστοσελίδων</h1>
              <h2 class="h5"><span></span>Δημιουργία και σχεδιασμός της δικής σας ιστοσελίδας!</h2>`
            }
          />
          <section className="webdesign__development">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <h2 className="development-header">Design is thinking made visual</h2>
                </div>
                <div className="col-md-9 align-self-center">
                  <h2 className="h5">Κατασκευή ιστοσελίδας που θα κατακτήσει όλες τις μηχανές αναζήτησης</h2>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p>Είναι ακριβώς η επαγγελματική ιστοσελίδα που έχετε σκεφτεί, ένα website που μπορεί να κάνει όλα όσα χρειάζεστε. Είναι θέμα σωστού σχεδιασμού, αισθητικής απεικόνισης, οργάνωσης και διαχείρισης του περιεχομένου. Έχουμε την ομάδα, τους προγραμματιστές και το know how για το σχεδιασμό και την κατασκευή ιστοσελίδων με επιβλητικό design, πρωτότυπο copywriting και σύγχρονο SEO. Το website στο μέλλον.</p>
                  <strong>Think Plus για τον σχεδιασμό της δικής σας επαγγελματικής ιστοσελίδας. Επιτρέψτε μας να δημιουργήσουμε μαζί ένα πραγματικά σύγχρονο website.</strong>
                  <h3 className="h5">Κατασκευή Ιστοσελίδων με responsive layout για desktop, tablet και smartphone!</h3>
                  <div className="development-solutions">
                    <p className="solutions__item">
                                          <svg viewBox="0 0 66 59" xmlns="http://www.w3.org/2000/svg"><g strokeWidth="2" fill="none" fillRule="evenodd"><path d="M48.135 47h6.568a.295.295 0 00.297-.293V9.292A.295.295 0 0054.703 9H10.297a.295.295 0 00-.298.292v37.415c0 .162.133.293.298.293H21.58M10 17h45M14 13h3M19 13h3M23 13h3"/><path d="M23.286 26.517h25.508c.136 0 .235.128.199.258L45.342 39.85a.206.206 0 01-.2.15H23.88a.206.206 0 01-.199-.149l-4.765-16.705a.204.204 0 00-.197-.147H13M25 32h20M26 36h17M32 47a3 3 0 11-6 0 3 3 0 016 0zM44 47a3 3 0 11-6 0 3 3 0 016 0z"/></g></svg>
                      <strong>E-commerce solutions</strong>
                      <span>Μαζί δημιουργούμε επιτυχημένες λύσεις για την ανάπτυξη ενός e-shop.</span>
                    </p>
                    <p className="solutions__item">
                                          <svg viewBox="0 0 66 59" xmlns="http://www.w3.org/2000/svg"><g strokeWidth="2" fill="none" fillRule="evenodd"><path d="M56.343 53H9.657c-.598 0-1.108-.458-1.204-1.081L8 49h20.752l.794 1.205h5.734L36.138 49H58l-.452 2.919C57.45 52.542 56.94 53 56.342 53zM13 20h-2.675c-.18 0-.325.13-.325.289v28.71M57 49V20.289c0-.16-.153-.289-.341-.289H53M14 45h36M49.769 40H15.231a.227.227 0 01-.231-.223V6.224C15 6.1 15.103 6 15.23 6h34.538c.128 0 .231.1.231.224v33.553a.227.227 0 01-.231.223zM14 12h35M19 9h3M23 9h3M26 9h3M20 19h3M24 19h13M43 19h-4M20 22h3M24 22h5M47 22H33M20 27h3M24 27h13M20 30h3M24 30h8M41 30h-6M20 35h3M24 35h15M43 35h-3"/></g></svg>
                      <strong>Κατασκευή Ιστοσελίδων</strong>
                      <span>Παρέχουμε υπηρεσίες ανάπτυξης λογισμικού σε: PHP, CSS, mySQL, JavaScript/jQuery και άλλων σύγχρονων τεχνολογιών.</span>
                    </p>
                    <p className="solutions__item">
                                          <svg viewBox="0 0 66 59" xmlns="http://www.w3.org/2000/svg"><g strokeWidth="2" fill="none" fillRule="evenodd"><path d="M54.709 52c.16 0 .29-.13.29-.29V8.29a.29.29 0 00-.29-.29H11.29a.29.29 0 00-.29.29V51.71c0 .16.13.289.29.289h43.419zM11 16h44M15 12h3M20 12h3M24 12h3"/><path d="M24 27.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM36 43.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM47 33.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM22 32v13M33 25v14M44 38v8M44 25v5"/></g></svg>
                      <strong>CMS, WordPress</strong>
                      <span>Έχουμε αναπτύξει σύστημα διαχείρισης περιεχομένου (CMS), το οποίο σας επιτρέπει να διαχειρίζεστε το περιεχόμενο του ιστοτόπου σας αποτελεσματικά και γρήγορα.</span>
                    </p>
                  </div>
                  <h3 className="h5">Δείτε την ψηφιακή σας εικόνα με άλλο μάτι!</h3>
                  <p>Επιτρέψτε μας να δημιουργήσουμε και να σχεδιάσουμε μαζί, βήμα-βήμα, τη νέα σας ιστοσελίδα και δείτε τα αποτελέσματα της επιτυχίας άμεσα!</p>
                </div>
              </div>
            </div>
          </section>
          <section className="eshop-development">
            <div className="background__theme">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <GatsbyImage
                      image={data.open.childImageSharp.gatsbyImageData}
                      alt="Eshop development"
                      className="development__eshop-img" />
                  </div>
                  <div className="col-md-6">
                    <h2 className="h1">ΚΑΤΑΣΚΕΥΗ E-SHOP</h2>
                    <h3 className="h5">B2C; B2B; Ή μήπως και τα δύο; <br />Ελλάδα; Εξωτερικό; Ή μήπως και τα δύο;</h3>
                    <p className="h6">Ότι και αν επιλέξετε η απάντηση είναι e-commerce!</p>
                    <p>Ενεργοποιήστε τη λειτουργία e-commerce και απογειώστε τις δυνατότητες της επιχείρισής σας.  Το e-shop αποτελεί το απόλυτο must-have στη σύγχρονη ψηφιακή εποχή. Κατασκευάστε σήμερα το ηλεκτρονικό σας κατάστημα με τη Think Plus και αποκτήστε ισχυρή παρουσία στο διαδίκτυο. E-shop με πωλήσεις!</p>
                    <p>Κατασκευάζουμε e-shop που βασίζονται στην ποιότητα, τη φιλικότητα προς το χρήστη και τον διαχειριστή, καθώς και την ασφάλεια στις συναλλαγές. Γνωρίζουμε την τεχνολογία του σήμερα και σχεδιάζουμε e-shop &amp; κατασκευή ιστοσελίδων πλήρως συμβατά με τις μηχανές αναζήτησης, απογειώνοντας την επισκεψιμότητα σας! Παράλληλα, η χρήση πρωτότυπων κειμένων και εικαστικών σε συνδυασμό με την εύκολη περιήγηση του χρήστη θα εξασφαλίσουν άμεσα την προσθήκη του online καταστήματος σας στα ‘favorites’ των πελατών σας.</p>
                    <p>Από την πλευρά μας δεν κοιτάμε μόνο τους πελάτες σας, αλλά φροντίζουμε και για το διαχειριστή του e-shop σας. Διαμορφώνουμε για σας ένα φιλικό περιβάλλον διαχείρισης του online καταστήματος για να έχετε πάντα τον έλεγχο. Customer service, παραγγελίες, πληρωμές και αποστολές με λίγα μόνο κλικ από τον υπολογιστή σας!</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 text-align-center">
                  <p className="eshop-development__create"><span style={{fontWeight: 'bold'}}>Δημιουργήστε το <br />ηλεκτρονικό <br />κατάστημα</span><br /> της επιχείρησης <br />σας και κάντε <br /><span style={{fontWeight: 'bold'}}>πωλήσεις <br />24 ώρες το <br />24ωρο.</span></p>
                  <p className="eshop-development__ask"><Link to="/contact-us" className="btn__theme">Ας δούμε μαζί το project σας!</Link></p>
                </div>
                <div className="col-md-6">
                  <h3 className="h4">Ενδεικτικά χαρακτηριστικά για την κατασκευή eshop:</h3>
                  <ul className="list--plus">
                    <li>Ανάπτυξη ιστοτόπου με Html5 για αποτέλεσμα πιο φιλικό στις μηχανές αναζήτησης και βελτιστοποιημένο αποτέλεσμα στις κινητές συσκευές (iphone, ipad, android κτλ).</li>
                    <li>Κατηγοριοποίηση προϊόντων σε απεριόριστα επίπεδα κατηγοριών και υποκατηγοριών.</li>
                    <li>Αναλυτική παρουσίαση κάθε προϊόντος ή υπηρεσίας με φωτογραφίες, κατηγορία, υποκατηγορία, κωδικό, εταιρεία, περιγραφή και τιμή. Οποιαδήποτε άλλη προδιαγραφή μπορεί να συμπεριληφθεί.</li>
                    <li>Σύστημα Back Office / CMS του ηλεκτρονικού καταστήματος για την διαχείριση των παραγγελιών σας.</li>
                    <li>Πλήρη διαχείριση της βάσης δεδομένων των υπηρεσιών με εύκολο στην χρήση περιβάλλον για προσθήκες, αφαιρέσεις και κάθε είδους αλλαγή.</li>
                    <li>Διαδικασία εγγραφής (Sign In).</li>
                    <li>Αναζήτηση στην ιστοσελίδα σας και σύνθετη αναζήτηση.</li>
                    <li>Λίστα προτιμήσεων (Wish List).</li>
                    <li>Λειτουργία σχετικών προϊόντων (related products).</li>
                    <li>Δυναμική ενότητα διαχείρισης εταιρικών νέων.</li>
                    <li>Δυναμική ενότητα ανανέωσης προσφορών, νέων προϊόντων.</li>
                    <li>Δυνατότητα του πελάτη να βλέπει την καρτέλα του και το ιστορικό των παραγγελιών του.</li>
                    <li>Υπηρεσία αποστολής κωδικού στο email του πελάτη σε περίπτωση απώλειας του κωδικού.</li>
                    <li>Δυνατότητα εγγραφής του επισκέπτη για newsletters.</li>
                    <li>Εταιρική παρουσίαση με πλήρη ανάλυση του ιστορικού της εταιρίας, δραστηριοτήτων, οικονομικών μεγεθών.</li>
                    <li>Δυνατότητα το ηλεκτρονικό κατάστημα να κατασκευαστεί και στην αγγλική γλώσσα.</li>
                    <li>Δυνατότητα B2B (χονδρικής πώλησης) με ειδικό login και εκπτωτική πολιτική ανά πελάτη.</li>
                    <li>Επιλογή γλώσσας του χρήστη</li>
                    <li>Επιλογή νομίσματος και αυτόματη μετατροπή του σε όλα τα προϊόντα</li>
                    <li>Δυνατότητα αλλαγής συντελεστών ΦΠΑ</li>
                  </ul>
                </div>
              </div>
              <div className="row text-align-center">
                <div className="col">
                  <strong className="mt-5">Think Plus για τον σχεδιασμό ενός σύγχρονου eshop από μια βραβευμένη ομάδα.</strong><br />
                  <strong>Επιτρέψτε μας να δημιουργήσουμε μαζί την επιτυχία σας.</strong>
                </div>
              </div>
            </div>
          </section>
          <section className="portal-development background__theme--dark">
            <div className="container">
              <SectionHeader
                title={'ΚΑΤΑΣΚΕΥΗ'}
                text={'INTERNET PORTAL'}
              />
              <p className="h5">Ο παγκόσμιος ιστός είναι στα χέρια σας και είστε έτοιμοι να απογειώστε την διαδικτυακή σας παρουσία με ένα ισχυρό web portal από την Think plus.</p>
              <p>Σχεδιάζουμε τη διαδικτυακή σας πύλη με γνώμονα τη φιλικότητα τόσο ως προς τον επισκέπτη όσο και τον Web administrator. Κατηγοριοποιούμε κατάλληλα τις θεματικές ενότητες, προσθέτουμε συνεχώς πρωτότυπο κείμενο και φιλοδοξούμε το δικό σας web portal να γίνει η αγαπημένη σελίδα των επισκεπτών σας. Όλος ο κόσμος στο portal σας!</p>
              <p className="h5">Σχεδιάζουμε και διαχειριζόμαστε το Internet Portal σας και το όραμά σας είναι και δικό μας!</p>
              <div className="portal__continuous">
                <p className="h5">Συνεχής αύξηση επισκεπτών</p>
                <div className="continuous--hr" aria-hidden="true"></div>
                <p className="h5">Συνεχής αύξηση διαφημιζόμενων</p>
              </div>
              <div className="portal__icons">
                <div className="portal__icon">
                                  <svg viewBox="0 0 97 89" xmlns="http://www.w3.org/2000/svg"><g transform="translate(15 12)" fill="none" fillRule="evenodd"><circle cx="33.35" cy="33.35" r="33.35"/><path d="M28.227 22.032H38.76a.75.75 0 00.75-.75V10.75a.75.75 0 00-.75-.75H28.227a.75.75 0 00-.75.75v10.532c0 .414.336.75.75.75zm.75-10.532h9.032v9.032h-9.032V11.5zm23.301 27.374H41.746a.75.75 0 00-.75.75v10.532c0 .414.335.75.75.75h10.532a.75.75 0 00.75-.75V39.624a.75.75 0 00-.75-.75zm-9.782 1.5h9.032v9.032h-9.032v-9.032zm-17.212-1.5H14.75a.75.75 0 00-.75.75v10.532c0 .414.337.75.75.75h10.534a.75.75 0 00.75-.75V39.624a.75.75 0 00-.75-.75zm-9.784 1.5h9.034v9.032H15.5v-9.032zm4.475-2.822a.75.75 0 00.75-.75v-5.058h25.536v5.058a.75.75 0 101.5 0v-5.808a.75.75 0 00-.75-.75H34.303V24.12a.75.75 0 10-1.5 0v6.124H19.975a.75.75 0 00-.75.75v5.808c0 .414.336.75.75.75z"/></g></svg>
                  <p>Online PR Management</p>
                </div>
                <div className="portal-icons--hr" aria-hidden="true"></div>
                <div className="portal__icon">
                                  <svg viewBox="0 0 97 89" xmlns="http://www.w3.org/2000/svg"><g transform="translate(15 11)" fill="none" fillRule="evenodd"><circle cx="33.65" cy="33.35" r="33.35"/><path d="M29.974 34.324a4.02 4.02 0 004.014 4.016c2.214 0 4.017-1.802 4.017-4.016s-1.803-4.015-4.017-4.015a4.02 4.02 0 00-4.014 4.015zm6.531 0a2.52 2.52 0 01-2.517 2.516 2.519 2.519 0 01-2.514-2.516 2.519 2.519 0 012.514-2.515 2.52 2.52 0 012.517 2.515zm-21.363-1.527a.75.75 0 00.834-.654c.98-8.141 7.345-14.596 15.476-15.699a.748.748 0 00.643-.843.746.746 0 00-.844-.643c-8.808 1.194-15.703 8.187-16.764 17.005a.75.75 0 00.655.834zm18.484-10.416a.75.75 0 00.75-.75V9.75a.75.75 0 00-1.5 0v11.881c0 .414.336.75.75.75zm2.194-6.016c8.271.874 15 7.51 15.998 15.778a.748.748 0 101.488-.18c-1.08-8.955-8.367-16.142-17.328-17.09a.75.75 0 10-.158 1.492zm16.832 19.491a.749.749 0 00-.834.654c-.998 8.266-7.725 14.901-15.998 15.775a.75.75 0 10.158 1.492c8.961-.948 16.248-8.133 17.328-17.087a.748.748 0 00-.654-.834zm-30.969-1.53a.75.75 0 00-.75-.75H9.05a.75.75 0 100 1.5h11.883a.75.75 0 00.75-.75zM33.626 46.27a.75.75 0 00-.75.75v11.884a.75.75 0 001.5 0V47.02a.75.75 0 00-.75-.75zm24.575-12.694h-11.88a.75.75 0 000 1.5h11.88a.75.75 0 100-1.5zm-26.749 18.63A18.021 18.021 0 0115.976 36.51a.749.749 0 10-1.489.18 19.522 19.522 0 0016.764 17.002.75.75 0 10.201-1.486z"/></g></svg>
                  <p>Social Mentions Monitoring</p>
                </div>
                <div className="portal-icons--hr" aria-hidden="true"></div>
                <div className="portal__icon">
                                  <svg viewBox="0 0 97 89" xmlns="http://www.w3.org/2000/svg"><g transform="translate(15 11)" fill="none" fillRule="evenodd"><circle cx="33.35" cy="33.35" r="33.35"/><path d="M15 45.379h36.333L33.167 14z"/><path d="M34.818 40.424h-3.303v-3.303h3.303zM34.818 33.818h-3.303v-6.606h3.303z"/></g></svg>
                  <p>Negative Content Filtering</p>
                </div>
                <div className="portal-icons--hr" aria-hidden="true"></div>
                <div className="portal__icon">
                                  <svg viewBox="0 0 97 89" xmlns="http://www.w3.org/2000/svg"><g transform="translate(15 11)" fill="none" fillRule="evenodd"><circle cx="34.225" cy="34.146" r="33.35"/><path d="M31.429 26.8c0-3.19-2.571-5.8-5.714-5.8C22.572 21 20 23.61 20 26.8s2.572 5.8 5.715 5.8c.857 0 1.571-.145 2.285-.58l3.429 3.48L28 38.98c-.714-.435-1.428-.58-2.285-.58-3.143 0-5.715 2.61-5.715 5.8s2.572 5.8 5.715 5.8 5.714-2.61 5.714-5.8c0-.87-.143-1.595-.571-2.32l3.428-3.48 10 10.15H50L30.857 29.12c.429-.725.572-1.45.572-2.32zm-5.714 2.9c-1.572 0-2.857-1.305-2.857-2.9s1.285-2.9 2.857-2.9 2.857 1.305 2.857 2.9-1.286 2.9-2.857 2.9zm0 17.4c-1.572 0-2.857-1.305-2.857-2.9s1.285-2.9 2.857-2.9 2.857 1.305 2.857 2.9-1.286 2.9-2.857 2.9zm8.571-12.325c.429 0 .714.29.714.725s-.285.725-.714.725-.714-.29-.714-.725.286-.725.714-.725zM50 22.45h-5.714l-8.572 8.7 2.857 2.9 11.43-11.6z"/></g></svg>
                  <p>Negative Content Removal</p>
                </div>
              </div>
              <div className="row text-align-center">
                <div className="col">
                  <strong className="mt-5">Think Plus για τη δημιουργία ενός ισχυρού internet portal. Ας υλοποιήσουμε μαζί τους στόχους σας έναν - έναν!</strong>
                </div>
              </div>
            </div>
          </section>
          <section className="development-stats background__theme">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="stats__content">
                    <h2 className="h4">Κατασκευή Web Banners</h2>
                    <p>Όλα τα νέα προϊόντα και οι νέες υπηρεσίες σας μπορούν να γίνουν άμεσα γνωστά στο κοινό και τους πελάτες σας. Δείξτε το μπάνερ σας σε όλους!</p>
                    <p>Tα web banners αποτελούν αναπόσπαστο εργαλείο για ένα αποτελεσματικό Internet Marketing. Είτε έχετε νέα προϊόντα, είτε απλά θέλετε να προσελκύσετε περισσότερο κοινό ο τρόπος είναι ένας: Τα διαφημιστικά banners. Το δημιουργικό μας τμήμα σχεδιάζει για εσάς τα κατάλληλα web banners, που θα αυξήσουν την επισκεψιμότητα της ιστοσελίδας σας. Copywriting, φωτογραφίες και κινούμενα γραφικά από τη Think Plus για τα δικά σας πετυχημένα web banners. Δείξτε την επιτυχία σας! </p>
                    <strong className="mt-5">Think Plus για τη δημιουργία web banners με έμπνευση και αποτελεσματικότητα και σύγχρονα μέσα.</strong>
                    <hr className="hr--theme hr--half" />
                    <span className="h2">&gt; 1700</span>
                    <p>Οι διαφημίσεις (banner) που βλέπει ο μέσος χρήστης / μήνα</p>
                    <span className="h2">&lt; 1%</span>
                    <p>Το ποσοστό που κάνει κλικ</p>
                    <span className="h2">&gt; 2094</span>
                    <p>Banners / μήνα βλέπει το κοινό 25-34</p>
                    <span className="h2">50%</span>
                    <p>Από τα κλικ σε κινητά είναι τυχαία</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <GatsbyImage
                    image={data.webBanners.childImageSharp.gatsbyImageData}
                    alt="Κατασκευή web banners" />
                </div>
              </div>
            </div>
          </section>
          <section className="development-blogs">
            <div className="container">
              <div className="row">
                <div className="col text-align-center">
                  <h2 className="h1 dec-header--underline">BLOGS &amp; CONTENT PUBLISHING</h2>
                  <p className="h5">Τα blog αποτελούν ένα ακόμη δυνατό εργαλείο του internet marketing που πρέπει να έχετε ισχυρή παρουσία.</p>
                </div>
              </div>
              <div className="row align-items-center justify-content-center">
                <div className="col-md-6">
                  <div className="development-blogs__img">
                    <GatsbyImage image={data.blogs.childImageSharp.gatsbyImageData} alt="Blog publishing" />
                  </div>
                </div>
                <div className="col-md-6">
                  <p>Σχεδιάζουμε το κατάλληλο ιστολόγιο που ταιριάζει στο προφίλ της εταιρείας σας, ενώ τα πρωτότυπα και στοχευμένα κείμενα των έμπειρων copywriters μας εγγυώνται βομβαρδισμό από comments και share! Σας παραδίδουμε ένα ισχυρό blog με αυξημένο engagement και δυνατό SEO για τη συνολική διαδικτυακή σας παρουσία. Άλλωστε, τα γραπτά μένουν!</p>
                  <strong className="mb-3">Think Plus για τη δημιουργία μοναδικού περιεχομένου blog και content publishing με δυναμικά αποτελέσματα</strong>
                  <Link to="/contact-us/" className="btn__theme">Ας δούμε μαζί το project σας!</Link>
                </div>
              </div>
            </div>
          </section>
          <section className="development-hotel background__theme">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <h2 className="h1">ΞΕΝΟΔΟΧΕΙΑΚΕΣ ΛΥΣΕΙΣ</h2>
                  <p className="h5">Εμείς στη Think Plus είμαστε εδώ για να το πούμε σε όλο τον κόσμο! <br />Κατασκευή ιστοσελίδων αναδεικνύοντας τα μοναδικά χαρακτηριστικά του δικού σας ξενοδοχείου.</p>
                  <p>Επαγγελματικές φωτογραφίες, βίντεο, εξειδικευμένα εργαλεία και πρωτότυπα κείμενα αποτελούν τα βασικά συστατικά της νέας σας ιστοσελίδας με τη Think plus. Εφαρμόζουμε σύγχρονες τεχνικές SEO για να φιγουράρει το ξενοδοχείο σας στις πρώτες θέσεις της Google.</p>
                  <p>Στοχεύουμε στις απευθείας κρατήσεις των πελατών σας από την ιστοσελίδα ενώ παράλληλα φροντίζουμε ώστε να έχετε ένα φιλικό περιβάλλον διαχείρισης του website. Αυξήστε το online booking με τη Think Plus.</p>
                </div>
                <div className="col-md-6 text-align-right">
                  <p className="h5 text-align-center">Το δικό σας ξενοδοχείο είναι μοναδικό!</p>
                  <GatsbyImage image={data.hotel.childImageSharp.gatsbyImageData} alt="Hotel solutions" />
                  <Link to="/contact-us/" className="btn--more">Επικοινωνήστε μαζί μας <span>&rarr;</span></Link>
                </div>
              </div>
            </div>
          </section>
          <section className="development-cms background__theme--dark text-align-center">
            <div className="container">
              <SectionHeader
                title={'SYSTEMS'}
                text={'CONTENT MANAGEMENT'}
                />
                <hr className="hr--theme hr--small" />
              <p className="h5">Εμείς στη Think Plus σχεδιάζουμε τα πιο user-friendly CMS κάνοντας τη διαχείριση της ιστοσελίδας σας παιχνίδι!</p>
              <p>Αυτοματοποιούμε διαδικασίες στην online διαχείριση της ιστοσελίδας και σας παρέχουμε ολοκληρωμένες λύσεις διαχείρισης περιεχομένου για τον δικό σας web administrator.<br /> Τώρα με τη Think Plus διαχειρίζεστε την ιστοσελίδα σας μόνοι σας, online, και χωρίς να έχετε τεχνικές γνώσεις!</p>
            </div>
          </section>
          <section className="development-hosting text-align-center">
            <div className="container">
              <h2 className="h1 dec-header--underline">HOSTING &amp; DOMAIN NAMES</h2>
              <p className="h5">.gr, .com, .eu ή κάτι πιο φρέσκο;</p>
              <p className="h5">Βρείτε το domain name που σας ταιριάζει και ελάτε σε μας να το κατοχυρώσουμε πριν την Κατασκευή Ιστοσελίδων. Είμαστε πάντα δίπλα σας και φροντίζουμε για την ανανέωση του domain name διατηρώντας ακέραιη τη διαδικτυακή σας ταυτότητα.</p>
              <p>Από ένα απλό website μέχρι ένα internet portal με απαιτητικές υπηρεσίες streaming εμείς στη Think Plus έχουμε την Hosting λύση! Αν θέλετε γρήγορο φόρτωμα της ιστοσελίδας και αποφυγή τεχνικών προβλημάτων, τότε το web hosting της ιστοσελίδας με τη Think plus είναι μονόδρομος.</p>
              <Link to="/contact-us/" className="btn__theme">Μάθετε περισσότερα</Link>
            </div>
          </section>
          <section>
            <div className="background__theme--dark mb-5">
              <SectionHeader
                title="Κατασκευή Ιστοσελίδων"
                text="FAQ"
              />
            </div>
            <div className="container medium">
              <div className="row">
                <div className="col-md-6">
                  <Accordion
                    className="accordion--theme"
                    allowZeroExpanded={true}
                  >
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τί είναι το CMS - Content Management System;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>Ένα <span style={{fontWeight: 'bold'}}>CMS</span> (Content Management System) ή αλλιώς Σύστημα Διαχείρισης Περιεχομένου είναι μια εφαρμογή για την διαχείριση του περιεχομένου μιας ιστοσελίδας, που επιτρέπει σε χρήστες να δημοσιεύουν περιεχόμενο, χωρίς να έχουν ιδιαίτερες τεχνικές γνώσεις. Έτσι δίνεται η δυνατότητα στους ιδιοκτήτες των επιχειρήσεων να προβαίνουν άμεσα στις απαραίτητες αλλαγές στο website τους και να ενημερώνουν σχετικά με τις προσφορές τους, το αγοραστικό κοινό τους. Ένα από τα πιο γνωστά CMS είναι το WordPress.</p>
                        <p>Για περισσότερες πληροφορίες ακολουθήστε τον παρακάτω σύνδεσμο.<br /><span style={{fontWeight: 'bold'}}>Link: </span><Link to="/wordpress-website/">https://think-plus.gr/wordpress-website/</Link>.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τι είναι το WordPress;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>Με τον όρο WordPress, αναφερόμαστε σε ένα σύστημα διαχείρισης περιεχομένου (CMS Content Management System), που μπορεί να χρησιμοποιηθεί για τη κατασκευή οποιασδήποτε ιστοσελίδας. Η αρχική του χρήση προοριζόταν για την υλοποίηση blogspots. Παρόλα αυτά με τη πάροδο των χρόνων, εξελίχθηκε στο πλέον σύγχρονο, ασφαλές και δημοφιλές πρόγραμμα κατασκευής ιστοσελίδων, που χρησιμοποιείται σε παγκόσμιο επίπεδο. Είναι σημαντικό να αναφερθεί, ότι πρόκειται για λογισμικό ανοιχτού κώδικα (open source), εξαιρετικά φιλικού τόσο με τις μηχανές αναζήτησης, όσο και με τα κοινωνικά δίκτυα. Τέλος, γεγονός που αποδεικνύει την σημασία του είναι, ότι το WordPress αποτελεί τη βάση για το 31% των websites παγκοσμίως.</p>
                        <p>Η Think Plus επενδύει δυναμικά στο WordPress και διαθέτει μεγάλη εμπειρία στην κατασκευή ιστοσελίδων με βάση τη συγκεκριμένη πλατφόρμα. Η δημιουργία 1000 και πλέον websites στα τελευταία δέκα έτη, μας δίνει την δυνατότητα να μπορούμε να σας καθοδηγήσουμε σωστά, για το πως θα αποκτήσετε μία ιστοσελίδα που θα αναδείξει την επιχείρησή σας και θα αποτελεί ταυτόχρονα και ένα εξαιρετικό εργαλείο marketing. Το μόνο που χρειάζεται να κάνετε είναι να μας εμπιστευτείτε.</p>
                        <p>Για περισσότερες πληροφορίες ακολουθήστε τον παρακάτω σύνδεσμο.<br /><span style={{fontWeight: 'bold'}}>Link: </span><Link to="/wordpress-website/">https://think-plus.gr/wordpress-website/</Link>.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τί είναι το Shopify;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>Το Shopify είναι η καλύτερη SaaS πλατφόρμα, σε παγκόσμιο επίπεδο, που υποστηρίζει όλες τις μορφές του ηλεκτρονικού εμπορίου/e-commerce. Προσφέρει ένα πλήθος δυνατοτήτων που μπορούν να ικανοποιήσουν τις απαιτήσεις, διαφόρων τύπων επιχειρήσεων, είτε μικρών είτε μεσαίων. Το Shopify διαθέτει όλα εκείνα τα εργαλεία που χρειάζεται μια επιχείρηση για να δημιουργήσει το δικό της ηλεκτρονικό κατάστημα και να ξεκινήσει να πουλάει άμεσα, ότι επιθυμεί τόσο εντός όσο και εκτός Ελλάδας.</p>
                        <p>Για περισσότερες πληροφορίες ακολουθήστε τον παρακάτω σύνδεσμο.<br /><span style={{fontWeight: 'bold'}}>Link: </span><Link to="/kataskeyi-eshop-me-shopify/">https://think-plus.gr/kataskeyi-eshop-me-shopify/</Link>.</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τί εννοούμε με τον όρο SSGs - Static Site Generators;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Τα <span style={{fontWeight: 'bold'}}>SSGs</span> (Static Site Generators) είναι μια υβριδική προσέγγιση, που αφορά στο web development και επιτρέπει την δημιουργία ταχύτατων websites. Μάλιστα η Google, προτείνει αυτή την υβριδική προσέγγιση, καθώς απαιτείται λιγότερος χρόνος για την φόρτωση και την απεικόνιση μιας σελίδας. Αυτό σημαίνει καλύτερη κατάταξη στα οργανικά αποτελέσματα των μηχανών αναζήτησης. Ένα από τα πιο γνωστά Static Site Generators είναι το Gatsby.</p></AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
                <div className="col-md-6">
                  <Accordion
                    className="accordion--theme"
                    allowZeroExpanded={true}
                  >
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τι είναι το PageSpeed;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Το PageSpeed είναι ένας από τους πιο σημαντικούς δείκτες που αναφέρεται στην κατασκευή και την κερδοφορία, ενός website. Με το PageSpeed μετράμε τον χρόνο που χρειάζεται για να φορτώσει μια σελίδα. Όσο μεγαλύτερος ο χρόνος φόρτωσης μιας σελίδας τόσο μεγαλύτερο θα είναι το bounce rate. Αυτό ταυτόχρονα σημαίνει λιγοστές έως μηδενικές πωλήσεις για το eshop σας.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τι είναι το UX - User Experience;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Το UX, δηλαδή το User Experience αναφέρεται στη συνολική εμπειρία που προσφέρει ένα ψηφιακό οικοσύστημα στο χρήστη. Αυτό το οικοσύστημα μπορεί να είναι ένα website ή ένα application  κ.τ.λ. Όσο καλύτερη είναι η εμπειρία του χρήστη,  μετά από την επαφή του με την ιστοσελίδα μιας εταιρείας, τόσο μεγαλύτερη επιτυχία θα έχει η εταιρεία στο ηλεκτρονικό επιχειρείν και ακόμα περισσότερη κερδοφορία στο ηλεκτρονικό εμπόριο.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τι είναι το SEO - Search Engine Optimization;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>To SEO (Search Engine Optimization), αφορά στην βελτιστοποίηση ενός website με σκοπό την καλύτερη κατάταξη στα οργανικά αποτελέσματα των μηχανών αναζήτησης. Το SEO, μακροπρόθεσμα μπορεί να μειώσει το κόστος απόκτησης νέων πελατών και να αυξήσει την κερδοφορία ενός website, ενός eshop ή ενός application.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τι είναι το Google Analytics;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Το Google Analytics είναι μια δωρεάν υπηρεσία που επιτρέπει στους ιδιοκτήτες websites να αναλύουν την κίνηση και την κερδοφορία του site τους. Το Google Analytics προσφέρει επίσης ενημερώσεις και χρήσιμες συμβουλές για κάθε website.</p></AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          </section>
          <ContactForm />
        </article>
      </Layout>
    </>;
}

export default pageWebDesign
